@tailwind base;
@tailwind components;
@tailwind utilities;
/* Full-screen container with centered content */
.container {
  display: flex;
  justify-content: flex-start; /* Align the content to the left */
  align-items: center; /* Center vertically */
  height: 100vh;
  background-color: white; /* White background */
  padding-left: 50px; /* Increased padding on the left */
  padding-right: 50px; /* Added padding on the right for balance */
}

/* Text styling*/
.text-content {
  max-width: 700px; /* Limit the width for better readability */
  transition: transform 0.3s ease; /* Smooth transition for scaling */
}

/* Responsive adjustments for mobile */
@media (max-width: 640px) {
  .text-content {
    transform: scale(0.9); /* Zoom out on mobile */
  }

  .title {
    font-size: 2.5rem; /* Smaller title on mobile */
  }

  .description {
    font-size: 1.1rem; /* Smaller text on mobile */
  }
}

.title {
  font-size: 3rem; /* Large title for prominence */
  font-weight: 700;
  color: #FF3D00; /* Custom color for title */
  margin-bottom: 1rem;
}

.description {
  font-size: 1.25rem;
  color: #555; /* Lighter gray for normal text */
  margin-bottom: 1rem;
}

.bold {
  font-weight: bold;
}

/* List styling */
.list {
  list-style-type: none; /* Remove bullets */
  padding-left: 0;
  margin-bottom: 1rem;
}

.list li {
  margin-bottom: 0.5rem; /* Spacing between list items */
}

/* Link styling */
.link {
  font-size: 1.25rem;
  color: #1e90ff; /* Blue text to indicate links */
  text-decoration: underline;
  transition: color 0.3s;
}

.link:hover {
  color: #104e8b; /* Darker blue on hover */
}
